<template>
  <div id="app">
    <div class="logo_box">
      <img class="logo_img" alt="logo" src="./assets/logo.png">
    </div>
    <HelloWorld />
  </div>
</template>

<script>
 import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style>
  html,body,#app {
    width: 100%;
    height: 100%;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin: 0;
    overflow: hidden;
  }
  .logo_box{
    width: 100%;height: 12%;display: flex;align-items: center;justify-content: center;background-color: white;
  }
  .logo_img{
    width: auto;height: 100%;background-size: cover;background-repeat: no-repeat;background-position: center center;
  }
</style>
